import Cookies from 'js-cookie'
import type { ClientMethod, FetchOptions, ParseAsResponse, PathMethods } from 'openapi-fetch'
import createOpenApiClient from 'openapi-fetch'
import type {
    GetValueWithDefault,
    HttpMethod,
    MediaType,
    PathsWithMethod,
    ResponseObjectMap,
    SuccessResponse,
} from 'openapi-typescript-helpers'

import { ApiError, parseErrors } from './api-error'
import { paramsSerializer } from './params-serializer'
import type { AllPaths } from './paths'

type OpenApiData<TMethod, TAdditionalArgs> = ParseAsResponse<
    GetValueWithDefault<SuccessResponse<ResponseObjectMap<TMethod>>, MediaType, Record<string, never>>,
    TAdditionalArgs
>

type ApiHandledResponse<TMethod, TOptions> = {
    data: OpenApiData<TMethod, TOptions>
} & Pick<Response, 'headers' | 'status' | 'statusText'>

const withHandledError =
    <TMethodsByPath extends { [k in keyof TMethodsByPath]: PathMethods }, TMethodName extends HttpMethod>(
        fn: ClientMethod<TMethodsByPath, TMethodName, MediaType>,
    ) =>
    async <
        TPath extends PathsWithMethod<TMethodsByPath, TMethodName>,
        TOptions extends FetchOptions<TMethodsByPath[TPath][TMethodName]>,
    >(
        path: TPath,
        // eslint-disable-next-line @typescript-eslint/no-empty-object-type
        ...[options]: {} extends TOptions ? [TOptions?] : [TOptions]
    ): Promise<ApiHandledResponse<TMethodsByPath[TPath][TMethodName], TOptions>> => {
        try {
            const result = await fn(path, options as TOptions)

            if ('error' in result) {
                const { message, formErrors } = parseErrors(result.error)

                // returning here instead of throw because we are in a try/catch
                // eslint-disable-next-line unicorn/no-useless-promise-resolve-reject
                return Promise.reject(
                    new ApiError({
                        status: result.response.status,
                        message: message ?? result.response.statusText,
                        formErrors,
                    }),
                )
            }

            return {
                data: result.data,
                headers: result.response.headers,
                status: result.response.status,
                statusText: result.response.statusText,
            }
        } catch (error) {
            throw new ApiError({
                status: 500,
                message: error instanceof Error ? error.message : 'Something went wrong',
                originalError: error instanceof Error ? error : undefined,
            })
        }
    }

const csrftoken = Cookies.get('csrftoken')

const client = createOpenApiClient<AllPaths>({
    querySerializer: paramsSerializer,
    credentials: 'include',
    headers: csrftoken ? { 'X-CSRFToken': csrftoken } : undefined,
    baseUrl: process.env.NODE_ENV === 'test' ? 'http://localhost:9090' : undefined,
})

export const api = {
    get: withHandledError(client.GET),
    put: withHandledError(client.PUT),
    post: withHandledError(client.POST),
    patch: withHandledError(client.PATCH),
    delete: withHandledError(client.DELETE),
    head: withHandledError(client.HEAD),
}
